.case-comment-avatar {
    display: flex;
    align-items: center;
    justify-content: center;

    border: 1px #ddd solid;
    border-radius: 20px;
    width: 40px;
    height: 40px;
    padding: 5px;
    margin: 5px;
    background-color: #eee;
    font-weight: bolder;
    font-size: 16px;
    color: #333;
    letter-spacing: 0.10em;
}

.case-comment-item {
    display: flex;
    border: 1px #ddd solid;
    border-radius: 4px;
    padding: 15px;
    margin: 0 0 10px 10px;
}

.case-comment-name {
    color: #333;
    font-size: 16px;
    font-weight: bolder;
    margin-left: 10px;
}

.case-comment-date {
    color: #999;
    font-size: 16px;
    margin-left: 10px;
}

.case-comment-p {
    margin: 5px 10px 10px 10px;
}

.case-comments-section textarea {
    width: 100%;
    resize: vertical;
}
