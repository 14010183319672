.custom-modal {
    position: absolute;
    top: 0;
    left: 20%;
    right: 40%;
    bottom: 40px;
    background-color: transparent;
    height: 60%;
    width: 60%;
    min-width: 510px;
    outline: none;
    margin-top: 50px;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.custom-modal-content {
    padding: 20px;
    height: auto;
}

.modal-input {
    background: #fff !important;
}

.modal-submit {
    background-color: #063662;
    border-radius: 0;
    color: #fff;
    width: 45%;
    float: right;
    margin-top: 15px;
    margin-left: 15px;
}

.close {
    position: absolute;
    top: 6px;
    right: 6px;
    font-size: 16px;
    cursor: pointer;
}

.close:hover {
    color: #000;
}
