.datatable {
    padding-left: 50px;
    display: block;
    position: relative;
}

.datatable tbody > tr:last-child td {
    border-bottom: none;
}

.datatable tbody tr td:first-child {
    border-left: none;
}

.datatable tbody tr td:last-child {
    border-right: 1px solid #ddd;
}

.datatable thead tr th:first-child {
    border-left: 1px solid #ddd;
}

.datatable thead tr th:last-child {
    border-right: 1px solid #ddd;
}

.datatable__loading-indicator {
    background: rgba(255, 255, 255, 0.4);
    display: flex;
    align-items: center;
    justify-content: center;
    justify-items: center;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 100;
}

.datatable__loading-indicator > img {
    flex: 1;
    width: 40px;
    height: 40px;
}

.datatable__clickable {
    cursor: pointer;
}

.datatable__row {
    min-width: 40px;
}

.datatable__row__td {
    vertical-align: middle !important;
    text-overflow: ellipsis;
    overflow: hidden;
}

.datatable__row.datatable-select {
    display: flex;
    align-items: center;
    justify-content: center;
    justify-items: center;
    padding: 12px;
}

.datatable__inner {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
}

.datatable__table-wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    margin-bottom: 20px;
}

.datatable__header {
    text-overflow: ellipsis;
    overflow: hidden;
}

.datatable__header.sortable {
    cursor: pointer;
}

.datatable__header.not-sortable {
    padding-bottom: 12px;
    padding-top: 8px;
}

.datatable__header.action {
    text-align: center;
}

.datatable__header.datatable-select-all {
    display: flex;
    align-items: center;
    justify-content: center;
    justify-items: center;
    padding: 12px;
}

.datatable__row.datatable-select label {
    width: 100%;
    height: 100%;
    display: block;
}

.datatable__row.datatable-select input[type='checkbox'],
.datatable__header.datatable-select-all input[type='checkbox'] {
    margin: 0 auto;
    border: 1px solid #d2d6de;
    border-radius: 2px;
    max-width: 16px;
    box-sizing: border-box;
    padding: 0;
    flex: 1;
}

.datatable__filter-dropdown {
    width: 100%;
}

.datatable .filter-clear {
    position: absolute;
    right: 8px;
    top: 8px;
    color: hsl(0, 0%, 80%);
    cursor: pointer;
}

.datatable .filter-clear:hover {
    color: #999;
}

.datatable .filter {
    padding-right: 15px;
}

.datatable__filter-dropdown > button {
    background-color: white;
    width: 100%;
}

.datatable__pagination {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
}

.datatable__control-buttons {
    /* width: 40px; */
    position: absolute;
    left: 5px;
    top: 5px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    flex: 0 0 40px;
}

.datatable__control-buttons button {
    width: 32px;
    height: 32px;
    margin-bottom: 10px;
}

.datatable__table {
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-bottom: 0;
    transition: width 150ms ease-out;
}

.datatable__actions {
    text-align: center;
}

.datatable__col-actions {
    width: 80px;
}

.datatable__remove {
    text-align: center;
}

.datatable__loader {
    width: 100%;
    height: 20px;
    background-color: white;
}

.datatable__loader:hover {
    background-color: white;
}

.datatable__loader > .bar {
    position: relative;
    overflow: hidden;
    background: #e9ebee;
    width: 100%;
    height: 20px;
    display: block;
}
.datatable__loader > .bar::after {
    content: '';
    display: block;
    background-color: #dddfe2;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    transform: translateX(0);
    animation: 1.5s loading-placeholder ease-in-out infinite;
}

@keyframes loading-placeholder {
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(100%);
    }
}
