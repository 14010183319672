/*cursor: pointer;*/
/*width: 140px;*/
/*height: 140px;*/
/*border: dashed 5px;*/
/*background-color: lightgrey;*/
.dropzone-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.medical-dropzone-container {
    position: relative;
}

.medical-dropzone {
    position: relative;
}

.medical-target {
    position: absolute;
    z-index: 10;
    border: dashed 5px !important;
    background-color: lightgrey;
    cursor: pointer;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dropzone {
    display: flex;
    cursor: pointer;
    width: 140px;
    height: 140px;
    margin: auto;
    border: dashed 5px !important;
    background-color: lightgrey;
}

.dropzone.dropzone-error {
    border: dashed 5px red !important;
}

.dropzone-drag-text {
    margin: auto;
    padding: 5px;
    white-space: pre-wrap;
    word-wrap: break-word;
    word-break: break-all;
}

.medical-target .dropzone-drag-text {
    font-size: 32px;
}
