#notificationEditPage {
    position: relative;
}

.notification-modal .system_message_box {
    position: static;
}

.control__input-container {
    margin-bottom: 5px;
}

.control__label-container {
    margin-top: 5px;
}
