.financeSection__readonly {
    display: flex;
    align-items: center;
    justify-content: center;
}

.financeSection__readonly > span {
    flex: 1;
    display: block;
    width: 100%;
    height: 34px;
    padding-top: 6px;
    padding-bottom: 6px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 0;
    border-radius: 4px;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.financeSection__field {
    min-width: 60px;
}

.financeSection__amountField-label {
    padding-top: 6px;
}

.financeSection__reviewerContainer {
}

.financeSection__reviewerContainer > .row > .col-md-6 {
    max-width: 250px;
}

.financeSection__reviewerContainer > .row > .col-md-3:nth-child(1) {
    max-width: 180px;
}

#resetCalculationButton {
    margin-right: 50px;
    margin-top: -6px;
    margin-bottom: 0;
}

#billingTierRow {
    padding: 15px;
}
